<template>
  <div class="gender-view">
    <div class="center">
      <basic-selectable-box
        v-for="btn in buttons"
        :key="btn.value"
        class="button-box"
        :selected="qsGender===btn.value"
        @click="()=>handleClickGender(btn.value)"
      >
        <div :class="['icon', btn.iconClass]" />
        <div class="text-wrapper">{{ btn.label }}</div>
        <div class="icon check" />
      </basic-selectable-box>
    </div>
  </div>
</template>

<script>
import BasicSelectableBox from '../components/basic/BasicSelectableBox.vue'
import QueryStringMixin from '../mixins/QueryStringMixin'
import TrackingMixin from '../mixins/TrackingMixin'
import { ROUTER_NAMES } from '../settings'
export default {
  components: { BasicSelectableBox },
  mixins: [QueryStringMixin, TrackingMixin],
  computed: {
    buttons() {
      return [
        {
          label: this.$t('male'),
          value: 'M',
          iconClass: 'male',
        },
        {
          label: this.$t('female'),
          value: 'F',
          iconClass: 'female',
        },
        {
          label: this.$t('unisex'),
          value: 'U',
          iconClass: 'unisex',
        },
      ]
    },
  },
  mounted() {
    this.douglasTrack([])
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'SET_GENDER' })
  },
  methods: {
    handleClickGender(gender) {
      this.eventTracker.emitEvent('GENDER_CHANGED', { gender })
      this.$pushHistory({
        name: ROUTER_NAMES.RECOMMENDATIONS,
        query: { ...this.$route.query, qsGender: gender },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.gender-view
  position: relative
  width: 100%
  height: 100%

  .center
    position: absolute
    top: 50%
    left: 50%
    display: flex
    flex-direction: column
    align-items: center
    min-width: vw(300px)
    transform: translate(-50%, -50%)

    .button-box
      position: relative
      display: flex
      align-items: center
      width: 100%
      text-align: center

      .mobile &
        height: vw(60px)

      &:not(:last-child)
        margin-bottom: vh(20px)

        .mobile &
          margin-bottom: vw(20px)

      &:hover,
      &:active,
      &.selected
        .icon
          &.male
            m-icon("male-white", 35)

          &.female
            m-icon("female-white", 35)

          &.unisex
            m-icon("unisex-white", 35)

      &:active,
      &.selected
        .icon
          &.check
            m-icon("check-white", 35)
            right: vw(20px)
            left: auto

      .text-wrapper
        m-font-size(17, 25)
        width: 100%
        font-weight: 600

      .icon
        position: absolute
        top: 50%
        left: vw(20px)
        transform: translateY(-50%)

        &.male
          m-icon("male", 35)

        &.female
          m-icon("female", 35)

        &.unisex
          m-icon("unisex", 35)
</style>

<template>
  <div class="basic-selectable-box" :class="{selected}" @click="$emit('click',$event)">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    selected: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.basic-selectable-box
  height: vh(60px)
  border: 1px solid #E6E6E6
  border-radius: 4px
  background-color: #fff
  cursor: pointer
  transition: background-color 0.25s ease-in-out

  &:hover
    background-color: $primary-theme-color-dark
    color: #fff

  &:active,
  &.selected
    background-color: darken($primary-theme-color-dark, 10%)
    color: #fff
    cursor: default
</style>